import { Component, OnInit } from '@angular/core';
import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-egin-kide-probintzia-modal',
  templateUrl: './egin-kide-probintzia-modal.page.html',
  styleUrls: ['./egin-kide-probintzia-modal.page.scss'],
})
export class EginKideProbintziaModalPage implements OnInit {

  probintzia_list:any=[];	

  constructor(
  	private giappService: GiappService,
  	public modalController: ModalController,  
  ) { 

  }

  ngOnInit() {

  	this.service_get_options_herriak("");

  }

  service_get_options_herriak(herria_bilatu_term_in){

  	let is_init:boolean=true;

    //console.log('herria_bilatu_term_in='+herria_bilatu_term_in);

  	let options_herriak_row=JSON.stringify({"herria_bilatu_term":herria_bilatu_term_in});
    let options_herriak_post_data=JSON.stringify({"herria_bilatu":options_herriak_row});

      this.giappService.getOptionsHerriak(options_herriak_post_data).subscribe((r)=>{
        
        if(r!=null && r.herriak!=null){
          this.probintzia_list=r.herriak;
        }

        //console.log("getOptionsHerriak service: " + JSON.stringify(r))


        //this.set_probintziak_checked(is_init);
        
      });
  }

  dismissModal(dismiss_type='',egin_kide_probintzia_herria_aukeratzeko_in=null){

  	if(dismiss_type=='back'){

        //console.log('dismissModal probintzia');
        this.modalController.dismiss(null);

    }else if(dismiss_type=='on_egin_kide_probintzia_select_herria_aukeratzeko'){

        console.log("probintzia_herria_aukeratzeko_in.name="+egin_kide_probintzia_herria_aukeratzeko_in.name);
    
        let data_row:any=this.define_dismiss_data_row(dismiss_type,egin_kide_probintzia_herria_aukeratzeko_in);        

        this.modalController.dismiss(data_row); 

    }

  }

  on_egin_kide_probintzia_select_herria_aukeratzeko(probintzia){

  	//console.log("probintzia.name="+probintzia.name);

  	this.dismissModal("on_egin_kide_probintzia_select_herria_aukeratzeko",probintzia);

  }

  clickCancel(){

    this.dismissModal('back');

  }

  define_dismiss_data_row(dismiss_type='',egin_kide_probintzia_herria_aukeratzeko_in=null){

    let data_row:any=null;

    if(dismiss_type=='on_egin_kide_probintzia_select_herria_aukeratzeko'){

      let current_probintziak_selected_array=[];
      //console.log("define_dismiss_data_row="+egin_kide_probintzia_herria_aukeratzeko_in.name);
      current_probintziak_selected_array.push(egin_kide_probintzia_herria_aukeratzeko_in);

      data_row={dismiss_type:dismiss_type,egin_kide_probintzia_herria_aukeratzeko_in:egin_kide_probintzia_herria_aukeratzeko_in};

    }

    return data_row;

  }
}
