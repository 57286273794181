import { Component, OnInit } from '@angular/core';
//import { ActivatedRoute, Router } from '@angular/router';
//import { NavigationExtras } from '@angular/router';
import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { StorageService } from '../../core/storage.service';

@Component({
  selector: 'app-m038-gida-saila-modal',
  templateUrl: './m038-gida-saila-modal.page.html',
  styleUrls: ['./m038-gida-saila-modal.page.scss'],
})
export class M038GidaSailaModalPage implements OnInit {

  sailak_list:any[]=[];
  custom_access_token:string="";
  sailak_selected_array:any[]=[];
  filtroa_sailak_selected_array_in:any[]=[];
  //saila_bilatu_term:string="";
  saila_bilatu_term:string="";	

  constructor(
    private giappService: GiappService,
    private storage: StorageService,
    public modalController: ModalController,
    public navParams:NavParams, 
  ) { 

    this.filtroa_sailak_selected_array_in=this.navParams.get('filtroa_gida_sailak_selected_array');

  }

  ngOnInit() {
  }

  ionViewWillEnter(){

  	this.service_get_filtroa_gida_sailak_options_custom_access_token_async();
  
  }

  async service_get_filtroa_gida_sailak_options_custom_access_token_async(){
  	const val= await this.storage.getObject("custom_access_token")
    //.then((val) => {
        this.custom_access_token=val;
        this.service_get_filtroa_gida_sailak_options();
    //});
  }

  service_get_filtroa_gida_sailak_options(saila_bilatu_term_in=""){

      let current_gvars=this.giappService.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let post_data={"giapp_hedabideak_id":giapp_hedabideak_id,saila_bilatu_term:saila_bilatu_term_in};
     
      this.giappService.getFiltroaGidasailakOptions(post_data,this.custom_access_token).subscribe((r)=>{

          let is_init:boolean=true;
        
          this.sailak_list=r.sailak;
          //console.log("this.sailak_list num="+this.sailak_list.length);
          //console.log("sailak_list="+JSON.stringify(this.sailak_list));

          this.set_sailak_checked(is_init);
          
      });


  }

  onClickFiltroaGidaSailaBilatu(){

    console.log('onClickFiltroaGidasailaBilatu');

    /****
    if ( this.saila_bilatu_term.length < 3 ){
      console.log("onClickHerriaBilatuTerm motzegia")
      return;
    }
    ****/

    //OHARRA::::String hutsa reset egiteko modua da
    //if ( this.saila_bilatu_term.length >= 1 ){

      //console.log('onClickHerriaBilatuTerm='+this.saila_bilatu_term);

      this.service_get_filtroa_gida_sailak_options(this.saila_bilatu_term);

    //}

  
  }

  clickCancel(){

    this.dismissModal('filtroa_gida_sailak_back');

  }

  clickSailakSelect(){

    this.dismissModal();

  }

  on_saila_select(saila,event){
    //console.log(saila.id+"="+saila.name+'='+event.target.checked);

    let is_init:boolean=false;

    if(event.target.checked){
      this.add_sailak_selected_array(saila);
    }else{
      this.unset_sailak_selected_array(saila);
    }

    //this.print_sailak_selected_array();

    this.set_sailak_checked(is_init);

  }

  add_sailak_selected_array(saila){

    this.sailak_selected_array=[];

    if(!this.giappService.in_array(saila,"id",this.sailak_selected_array)){
     this.sailak_selected_array.push(saila);
    }
  
  }

  unset_sailak_selected_array(saila){

    let current_sailak_selected_array:any[]=this.sailak_selected_array;
    this.sailak_selected_array=[];
    let num=current_sailak_selected_array.length;
    //console.log('num='+num);
    let result:any[]=[];
    let row:any;
    
    if(num>0){
      let i:number=0;
      
      for(i=0;i<num;i++){

        row=current_sailak_selected_array[i];

        //if(row.name!=saila.name){
        if(row.id!=saila.id){  
          result.push(row);
        }

      }
    }

    this.sailak_selected_array=result;

  }

  dismissModal(dismiss_type="") {
      /*if (currentModal) {
        currentModal.dismiss().then(() => { currentModal = null; });
      }*/

      //this.modalController.dismiss(this.sailak_selected_array);
      
      let data:any={gida_sailak_selected_array:this.sailak_selected_array,dismiss_type:dismiss_type};

      this.modalController.dismiss(data);
  }

  set_sailak_checked(is_init){

    let num=this.sailak_list.length;
    //console.log('num='+num);
    let i:number=0;
    let saila:any=null;
    let is_checked:boolean=false;

    //console.log('is_init='+is_init);

    for(i=0;i<num;i++){

      saila=this.sailak_list[i];

      if(is_init){
        is_checked=this.set_init_saila_checked(saila);
      }else{
        is_checked=this.set_update_saila_checked(saila,this.sailak_selected_array)
      }
      
      this.sailak_list[i].is_checked=is_checked;

      if(is_checked){
        this.add_sailak_selected_array(saila);
      }
    }

  }

  set_update_saila_checked(saila,current_sailak_selected_array){

    if(current_sailak_selected_array!=null){
      let num=current_sailak_selected_array.length;
      //console.log('num='+num);
      let i:number=0;
      let row:any=null;

      if(num>0){

          for(i=0;i<num;i++){

          row=current_sailak_selected_array[i];
          
          if(row.id==saila.id){
            return true;
          }

        }

      }
    }     

    return false;
  }

  set_init_saila_checked(saila){

   
      //console.log('filtroa_sailak_selected_array_in='+this.filtroa_sailak_selected_array_in);
      let is_checked:boolean=this.set_update_saila_checked(saila,this.filtroa_sailak_selected_array_in);     

    return is_checked;
  }
}

