import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
//import { Input} from '@angular/core';
import { NavParams } from '@ionic/angular';
import { GiappService } from '../../core/giapp.service';
import { StorageService } from '../../core/storage.service';
//import { NavController } from '@ionic/angular'; 

@Component({
  selector: 'app-argazkia-aldatu-modal',
  templateUrl: './argazkia-aldatu-modal.page.html',
  styleUrls: ['./argazkia-aldatu-modal.page.scss'],
})
export class ArgazkiaAldatuModalPage implements OnInit {

  rol:string="";
  profila=null;
  custom_access_token:string="";
  badauka_profileko_argazkia:number=0;
  profileko_argazkia_url:string="";
  argazkia_file=null;	

  constructor(
    public giappService: GiappService,
    private storage: StorageService,
  	public modalController: ModalController,
  	public navParams:NavParams
  ) {
 
    this.rol = this.navParams.get('rol');
    console.log('rol modal='+this.rol);
    this.profila = this.navParams.get('profila');
    this.badauka_profileko_argazkia=this.navParams.get('badauka_profileko_argazkia');
    this.profileko_argazkia_url=this.navParams.get('profileko_argazkia_url');

  }

  ngOnInit() {
  }

  dismissArgazkiaAldatuModal(){
  	this.modalController.dismiss();
  }

  clickArgazkiaSave(){
  	
    //this.modalController.dismiss();
  
    this.service_user_photo_upload_custom_access_token_async();

  }

  clickCancel(){
  	this.modalController.dismiss();
  }

  profileko_argazkia_aukeratu_aldatzeko(){
    console.log("profileko_argazkia_aukeratu_aldatzeko");
  }

  onChangeArgazkiaFile(event){

    //console.log("onChangeArgazkiaFile");

     this.argazkia_file = event.target.files[0];
     //console.log(this.argazkia_file);

     
  }

  async service_user_photo_upload_custom_access_token_async(){
    const val=await this.storage.getObject("custom_access_token")
    //.then((val) => {
        this.custom_access_token=val;
        this.service_user_photo_upload();
    //});

  }

  service_user_photo_upload(){

    //console.log("service_user_photo_upload");

    //OHARRA::::"token_barra_t" parametroa Webguneko Api-ko CORS errorea zuzentzeko da

    this.giappService.userPhotoUpload(this.argazkia_file,this.custom_access_token,"token_barra_t").subscribe((r)=>{
    
      console.log("userPhotoUpload="+r);

      let is_argazkia_ondo_aldatu_da:number=1;

      let dismiss_data_row:any={is_argazkia_ondo_aldatu_da:is_argazkia_ondo_aldatu_da};

      this.modalController.dismiss(dismiss_data_row);

    });

  }

}
