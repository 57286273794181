import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeurl'
})

export class SafeurlPipe implements PipeTransform {
  
  constructor ( private _domSanitizer:DomSanitizer){}
  //constructor (  ){}

  transform(value: string ): any {
    
    //console.log("SSSS:: " + value )
    let uu=this._domSanitizer.bypassSecurityTrustResourceUrl( value );
    //let uu=this._domSanitizer.bypassSecurityTrustUrl( value );
    //console.log("uu:  " + JSON.stringify(uu));
    return uu;
  
  }

}

