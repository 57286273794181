import { Component, OnInit } from '@angular/core';
import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-p004-pasahitza-ez-da-zuzena-modal',
  templateUrl: './p004-pasahitza-ez-da-zuzena-modal.page.html',
  styleUrls: ['./p004-pasahitza-ez-da-zuzena-modal.page.scss'],
})
export class P004PasahitzaEzDaZuzenaModalPage implements OnInit {

  pasahitza_ez_da_zuzena_title:string="";
  pasahitza_ez_da_zuzena_status_error_msg:string="";	

  constructor(
    //private router: Router,
    //private route: ActivatedRoute,
    private giappService: GiappService,
    public modalController: ModalController,

  ) { 

  }

  ngOnInit() {
  }

  ionViewWillEnter(){

    //this.custom_access_token="";

    this.service_ezarpenak_load();
  }

  service_ezarpenak_load(){


    let current_gvars=this.giappService.getGlobalVars();
    let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
    let post_data:any={giapp_hedabideak_id:giapp_hedabideak_id};

    ///console.log("giapp_hedabideak_id="+giapp_hedabideak_id);
    
      this.giappService.ezarpenakLoad(post_data,"").subscribe((r)=>{
        
        this.set_pasahitza_ez_da_zuzena_ezarpenak_values(r);

      });
    
  }

  set_pasahitza_ez_da_zuzena_ezarpenak_values(r){

     if(r!=null){

      
      if(r.pasahitza_ez_da_zuzena_title){

      	this.pasahitza_ez_da_zuzena_title=r.pasahitza_ez_da_zuzena_title;
      }
  
  	  if(r.pasahitza_ez_da_zuzena_status_error_msg){

  	  	this.pasahitza_ez_da_zuzena_status_error_msg=r.pasahitza_ez_da_zuzena_status_error_msg;
  	  }

    }
  }

  dismissModal(dismiss_type="") {
     

    this.modalController.dismiss();
  }

  ados_click(){

  	this.dismissModal("back");
  }
}
