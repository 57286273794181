import { Component, OnInit } from '@angular/core';
import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';
//import { Input} from '@angular/core';
import { NavParams } from '@ionic/angular'; 

@Component({
  selector: 'app-zuzenekoa-modal',
  templateUrl: './zuzenekoa-modal.page.html',
  styleUrls: ['./zuzenekoa-modal.page.scss'],
})
export class ZuzenekoaModalPage implements OnInit {

	/****
  OHARRA::::Gerta daiteke zuzenekoa ez ikustea, Chromium-eko Developer Tools simuladorea aktibatua dagoenean, browser-ak dena
  okupatzen duenean, ondo doa
  *****/

  zuzenekoa:any={id:0,embed_kode_url:""};		

  constructor(
  	public giappService: GiappService,
  	public modalController: ModalController,
  	public navParams:NavParams
  ) {
  	this.zuzenekoa = this.navParams.get('zuzenekoa');
  	//console.log('zuzenekoa id modal='+this.zuzenekoa.id);

  }

  ngOnInit() {

  	//this.service_get_zuzenekoa();

  }


  dismissModal() {
      /*if (currentModal) {
        currentModal.dismiss().then(() => { currentModal = null; });
      }*/

      this.modalController.dismiss();
  }

  service_get_zuzenekoa(){

  	  let current_gvars=this.giappService.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let row={"giapp_hedabideak_id":giapp_hedabideak_id};
      let zuzenekoa_row=JSON.stringify(row);
      let zuzenekoa_post_data=JSON.stringify({"zuzenekoa":zuzenekoa_row});

      //console.log("modal barruan service_get_zuzenekoa giapp_hedabideak_id="+giapp_hedabideak_id);

      this.giappService.getZuzenekoa(zuzenekoa_post_data).subscribe((r)=>{
        
        this.zuzenekoa = r.zuzenekoa;

        //console.log('zuzenekoa id modal='+this.zuzenekoa.id);
        //console.log('zuzenekoa embed_kode_url='+this.zuzenekoa.embed_kode_url);
        
      });

  }
}
