import { Component, OnInit } from '@angular/core';
//import { ActivatedRoute, Router } from '@angular/router';
//import { NavigationExtras } from '@angular/router';
import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { StorageService } from '../../core/storage.service';

@Component({
  selector: 'app-m038-gida-azpi-saila-modal',
  templateUrl: './m038-gida-azpi-saila-modal.page.html',
  styleUrls: ['./m038-gida-azpi-saila-modal.page.scss'],
})
export class M038GidaAzpiSailaModalPage implements OnInit {

  azpisailak_list:any[]=[];
  custom_access_token:string="";
  azpisailak_selected_array:any[]=[];
  filtroa_azpisailak_selected_array_in:any[]=[];
  azpisaila_bilatu_term:string="";	

  constructor(
    private giappService: GiappService,
    private storage: StorageService,
    public modalController: ModalController,
    public navParams:NavParams, 
  ) { 

    this.filtroa_azpisailak_selected_array_in=this.navParams.get('filtroa_gida_azpisailak_selected_array');

  }

  ngOnInit() {
  }

  ionViewWillEnter(){
  	this.service_get_filtroa_gida_azpisailak_options_custom_access_token_async();
  }

  async service_get_filtroa_gida_azpisailak_options_custom_access_token_async(){
    const val= await this.storage.getObject("custom_access_token")
    //.then((val) => {  
        this.custom_access_token=val;
        this.service_get_filtroa_gida_azpisailak_options();
    //});
  }

  service_get_filtroa_gida_azpisailak_options(azpisaila_bilatu_term_in=""){

      let current_gvars=this.giappService.getGlobalVars();
      let giapp_hedabideak_id:number=current_gvars.giapp_hedabideak_id;
      let post_data={"giapp_hedabideak_id":giapp_hedabideak_id,azpisaila_bilatu_term:azpisaila_bilatu_term_in};
     
      this.giappService.getFiltroaGidaAzpiSailakOptions(post_data,this.custom_access_token).subscribe((r)=>{

          let is_init:boolean=true;
        
          this.azpisailak_list=r.azpisailak;
          //console.log("this.azpisailak_list num="+this.azpisailak_list.length);
          //console.log("azpisailak_list="+JSON.stringify(this.azpisailak_list));

          this.set_azpisailak_checked(is_init);
          
      });


  }

  onClickFiltroaGidaAzpiSailaBilatu(){

    console.log('onClickFiltroaGidaazpisailaBilatu');

     /****
    if ( this.azpisaila_bilatu_term.length < 3 ){
      console.log("onClickHerriaBilatuTerm motzegia")
      return;
    }
    ****/

    //OHARRA::::String hutsa reset egiteko modua da
    //if ( this.azpisaila_bilatu_term.length >= 1 ){

      //console.log('onClickHerriaBilatuTerm='+this.azpisaila_bilatu_term);

      this.service_get_filtroa_gida_azpisailak_options(this.azpisaila_bilatu_term);

    //}
  
  }

  clickCancel(){

    this.dismissModal('filtroa_gida_azpisailak_back');

  }

  clickAzpiSailakSelect(){

    this.dismissModal();

  }

  on_azpisaila_select(azpisaila,event){
    //console.log(azpisaila.id+"="+azpisaila.name+'='+event.target.checked);

    let is_init:boolean=false;

    if(event.target.checked){
      this.add_azpisailak_selected_array(azpisaila);
    }else{
      this.unset_azpisailak_selected_array(azpisaila);
    }

    //this.print_azpisailak_selected_array();

    this.set_azpisailak_checked(is_init);

  }

  add_azpisailak_selected_array(azpisaila){

    this.azpisailak_selected_array=[];

    if(!this.giappService.in_array(azpisaila,"id",this.azpisailak_selected_array)){
     this.azpisailak_selected_array.push(azpisaila);
    }
  
  }

  unset_azpisailak_selected_array(azpisaila){

    let current_azpisailak_selected_array:any[]=this.azpisailak_selected_array;
    this.azpisailak_selected_array=[];
    let num=current_azpisailak_selected_array.length;
    //console.log('num='+num);
    let result:any[]=[];
    let row:any;
    
    if(num>0){
      let i:number=0;
      
      for(i=0;i<num;i++){

        row=current_azpisailak_selected_array[i];

        //if(row.name!=azpisaila.name){
        if(row.id!=azpisaila.id){  
          result.push(row);
        }

      }
    }

    this.azpisailak_selected_array=result;

  }

  dismissModal(dismiss_type="") {
      /*if (currentModal) {
        currentModal.dismiss().then(() => { currentModal = null; });
      }*/

      //this.modalController.dismiss(this.azpisailak_selected_array);
      
      let data:any={gida_azpisailak_selected_array:this.azpisailak_selected_array,dismiss_type:dismiss_type};

      this.modalController.dismiss(data);
  }

  set_azpisailak_checked(is_init){

    let num=this.azpisailak_list.length;
    //console.log('num='+num);
    let i:number=0;
    let azpisaila:any=null;
    let is_checked:boolean=false;

    //console.log('is_init='+is_init);

    for(i=0;i<num;i++){

      azpisaila=this.azpisailak_list[i];

      if(is_init){
        is_checked=this.set_init_azpisaila_checked(azpisaila);
      }else{
        is_checked=this.set_update_azpisaila_checked(azpisaila,this.azpisailak_selected_array)
      }
      
      this.azpisailak_list[i].is_checked=is_checked;

      if(is_checked){
        this.add_azpisailak_selected_array(azpisaila);
      }
    }

  }

  set_update_azpisaila_checked(azpisaila,current_azpisailak_selected_array){

    if(current_azpisailak_selected_array!=null){
      let num=current_azpisailak_selected_array.length;
      //console.log('num='+num);
      let i:number=0;
      let row:any=null;

      if(num>0){

          for(i=0;i<num;i++){

          row=current_azpisailak_selected_array[i];
          
          if(row.id==azpisaila.id){
            return true;
          }

        }

      }
    }     

    return false;
  }

  set_init_azpisaila_checked(azpisaila){

   
      //console.log('filtroa_azpisailak_selected_array_in='+this.filtroa_azpisailak_selected_array_in);
      let is_checked:boolean=this.set_update_azpisaila_checked(azpisaila,this.filtroa_azpisailak_selected_array_in);     

    return is_checked;
  }
}

