import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { GiappService } from './giapp.service';
import { GiappExtraService } from './giapp-extra.service';
//import { BarcodeScanner, BarcodeScannerPlugin } from '@capacitor-community/barcode-scanner';
//import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root'
})
export class GiappQrService {

  custom_access_token:string="";	

  constructor(
  	private router: Router,
  	public giappService: GiappService,
  	public giappExtraService: GiappExtraService,
  	//public barcodeScanner: BarcodeScannerPlugin,
  	private iab: BrowserService,
  ) { 

  }

  prepare(){
    //this.barcodeScanner.prepare();
  };
  
  async startScan() {
    /*this.barcodeScanner.hideBackground();
    const result = await this.barcodeScanner.startScan();
    if (result.hasContent) {
      console.log("QRRRRRR RESULT " + result.content);
    }*/
  };

  stopScan() {
    //this.barcodeScanner.showBackground();
    //this.barcodeScanner.stopScan();
  };

  async didUserGrantPermission() {
    /*// check if user already granted permission
    const status = await this.barcodeScanner.checkPermission({ force: false });
    if (status.granted) {
      return true;
    }
    if (status.denied) {
      return false;
    }
    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }
    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = confirm(
        'We need your permission to use your camera to be able to scan barcodes',
      );
      if (!c) {
        return false;
      }
    }
  
    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      return false;
    }
  
    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await this.barcodeScanner.checkPermission({ force: true });
  
    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }
  
    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }
  
    // user did not grant the permission, so he must have declined the request
    return false;
    */
  };
  
  show_barcode_scan(rol_in){
    console.log("show_barcode_scan---------")

  }

  /*show_barcode_scan(rol_in){

  	//console.log("show_barcode_scan");

    //simulatzeko
    //return this.service_get_komertzioak_bereiz("proba",rol_in);
  
  	const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Jarri QR kodea eskaneatzeko lehioan.',
      resultDisplayDuration: 500,
      formats: 'QR_CODE,PDF_417 ',
      orientation: 'portrait',
    };

    this.barcodeCtrl.scan(options).then(barcodeData => {
      
      if (barcodeData['cancelled'] == false ){
        if(barcodeData['text']){
          try {
            let url = new URL(barcodeData['text']);

            if ( barcodeData['text'].includes("goiena.eus/gida/")  ) {
              //Gida komertziala

              let t=barcodeData['text'].split("goiena.eus/gida/")[1]
              if ( t.includes("-")  ){
                t=t.replace(/-/g,"_")
              }
              this.service_get_komertzioak_bereiz(t,rol_in);
              
            }else{
              //Web orri arrun bat
              let options=this.giappExtraService.define_iab_options();
              let browser = this.iab.create(barcodeData['text'],"_blank",options);
            }

            //this.show_qr_result_modal(barcodeData['text']);
          } catch (_) {
            alert( barcodeData['text'] );
          }
        }
      }else{
        console.log(".....cancel.....");
      }


    }).catch(err => {
      console.log('Error', err);
    });

  }*/

  service_get_komertzioak_bereiz(title,rol_in) {
    
    let current_gvars = this.giappService.getGlobalVars();
    let giapp_hedabideak_id: number = current_gvars.giapp_hedabideak_id;

    let ionic_zerrenda_type: string = "herria_komertzioak";
    ionic_zerrenda_type = "komertzioak_bilatu";

    let f="{\"bilatu_testua\": \""+title+"\",\"herriak_id_array\":[],\"gida_herriak_selected_array\":[],\"sailak_id_array\":[],\"gida_sailak_array\":[],\"azpisailak_id_array\":[],\"gida_azpisailak_array\":[]}";
    let post_data={
      "giapp_hedabideak_id": giapp_hedabideak_id, 
      "ionic_zerrenda_type": ionic_zerrenda_type,
      "herria_id": [],
      "komertzioak_bilatu":"",
      "giapp_limit":"all",
      "is_barcode_scanner": 1,  
    }
    post_data.komertzioak_bilatu=f;

    console.log(JSON.stringify(post_data));

    this.giappService.getGidaKomertzioakBereiz(JSON.stringify(post_data), this.custom_access_token).subscribe((r) => {

      //console.log("bilatu_testua_where_string="+r.bilatu_testua_where_string);

      if(!r){
        return false;
      }else if(r && (<any>r['komertzioak']).length ==1 ){
        const navigationExtras: NavigationExtras = { state: { rol: rol_in,gida_negozioa_in:r['komertzioak'][0] } };
        this.router.navigate(['/members/m038-gida-fitxa-show'], navigationExtras);
        return true;  
      }else{
        //LEHENA BAKARRA ERAKUTSI---------
        var rol=rol_in;
        var router=this.router;
        (<any>r['komertzioak']).every(function( k ) {
          if(k.title.toLowerCase()==title.toLowerCase()){
            const navigationExtras: NavigationExtras = { state: { rol: rol,gida_negozioa_in:k } };
            router.navigate(['/members/m038-gida-fitxa-show'], navigationExtras);
            return true;  
          }
        });
      }
    });
  }
}
