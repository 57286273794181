import { Component, OnInit } from '@angular/core';
//import { ActivatedRoute, Router } from '@angular/router';

import { GiappService } from '../../core/giapp.service';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import { StorageService } from '../../core/storage.service';
//import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-user-herria-modal',
  templateUrl: './user-herria-modal.page.html',
  styleUrls: ['./user-herria-modal.page.scss'],
})
export class UserHerriaModalPage implements OnInit {

  herria_list=[];
  user_probintzia_herria_aukeratzeko_in:any=null;
  user_herriak_selected_array_in:any=null;
  user_herriak_selected_array:any=[];
  herria_bilatu_term:string="";		

  constructor(
  	private giappService: GiappService,    
  	public modalController: ModalController,
  	public navParams:NavParams,
  ) { 

  	this.user_probintzia_herria_aukeratzeko_in=this.navParams.get('user_probintzia_herria_aukeratzeko_in');
  	this.user_herriak_selected_array_in=this.navParams.get('user_herriak_selected_array_in');

  }

  ngOnInit() {

  	this.service_get_options_herriak("");

  }

   service_get_options_herriak(herria_bilatu_term_in){

  	//let current_herria_bilatu_term="";
  	//herria_bilatu_term="arr";
  	let is_init:boolean=true;

    //console.log('herria_bilatu_term_in='+herria_bilatu_term_in);

    let giapp_user_probintzia_name_selected:string=this.get_giapp_user_probintzia_name_selected();
    let options_herriak_row=JSON.stringify({"herria_bilatu_term":herria_bilatu_term_in,"giapp_probintzia_name_selected":giapp_user_probintzia_name_selected});
    let options_herriak_post_data=JSON.stringify({"herria_bilatu":options_herriak_row});

    console.log("giapp_user_probintzia_name_selected="+giapp_user_probintzia_name_selected);

      this.giappService.getOptionsHerriak(options_herriak_post_data).subscribe((r)=>{
        
        if(r!=null && r.herriak!=null){
          this.herria_list=r.herriak;
        }

        //console.log("getOptionsHerriak service: " + JSON.stringify(r))


        this.set_user_herriak_checked(is_init);
        
      });
  }

  get_giapp_user_probintzia_name_selected(){

  	let user_probintzia_name:string="";

  	if(this.user_probintzia_herria_aukeratzeko_in!=null){

  		if(this.user_probintzia_herria_aukeratzeko_in.name!=null){

  			user_probintzia_name=this.user_probintzia_herria_aukeratzeko_in.name;

  		}

  	}

  	return user_probintzia_name;

  }

  onClickUserHerriaBilatuTerm(){
    
    //console.log('onClickHerriaBilatuTerm');

    /****
    if ( this.herria_bilatu_term.length < 3 ){
      console.log("onClickHerriaBilatuTerm motzegia")
      return;
    }
    ****/

    //OHARRA::::String hutsa reset egiteko modua da
    //if ( this.herria_bilatu_term.length >= 1 ){

      //console.log('onClickHerriaBilatuTerm='+this.herria_bilatu_term);

      this.service_get_options_herriak(this.herria_bilatu_term);

    //}

  }

  on_user_herria_select(herria,event){
  	//console.log(herria.tid+"="+herria.name+'='+event.target.checked);

  	let is_init:boolean=false;

  	if(event.target.checked){
  	  this.add_user_herriak_selected_array(herria);
  	  //this.desel_beste_herriak(herria);

      //this.unset_herriak_tmp_ez_array(herria);
  	}else{
  	  this.unset_user_herriak_selected_array(herria);

      //this.add_herriak_tmp_ez_array(herria);
  	}

  	//this.print_herriak_selected_array();

  	this.set_user_herriak_checked(is_init)
  }

  set_user_herriak_checked(is_init){

  	let num=this.herria_list.length;
  	//console.log('num='+num);
  	let i:number=0;
  	let herria:any=null;
  	let is_checked:boolean=false;

  	//console.log('is_init='+is_init);

  	for(i=0;i<num;i++){

  		herria=this.herria_list[i];

  		if(is_init){
  			is_checked=this.set_init_user_herria_checked(herria);
  		}else{
  			is_checked=this.set_update_user_herria_checked(herria,this.user_herriak_selected_array);
  		}
  		
  		this.herria_list[i].is_checked=is_checked;

  		if(is_checked){
  			this.add_user_herriak_selected_array(herria);
  		}
  	}

  }

  set_init_user_herria_checked(herria){

  	let is_checked:boolean=this.set_update_user_herria_checked(herria,this.user_herriak_selected_array_in);	  	

  	return is_checked;
  }

  set_update_user_herria_checked(herria,current_herriak_selected_array){

  	if(current_herriak_selected_array!=null){
	  	let num=current_herriak_selected_array.length;
	  	//console.log('num='+num);
	  	let i:number=0;
	  	let row:any=null;

	  	if(num>0){

	  	  	for(i=0;i<num;i++){

		  		row=current_herriak_selected_array[i];
		  		
		  		if(row.tid==herria.tid){
		  			return true;
		  		}

		  	}

		  }
	  }	  	

  	return false;
  }

  add_user_herriak_selected_array(herria){

  	this.user_herriak_selected_array=[];

    if(!this.giappService.in_array(herria,"tid",this.user_herriak_selected_array)){

  	 this.user_herriak_selected_array.push(herria);

    }
  }

  unset_user_herriak_selected_array(herria){

  	let current_herriak_selected_array:any[]=this.user_herriak_selected_array;
  	this.user_herriak_selected_array=[];
  	let num=current_herriak_selected_array.length;
  	//console.log('num='+num);
  	let result:any[]=[];
  	let row:any;
  	
  	if(num>0){
  		let i:number=0;
  		
  		for(i=0;i<num;i++){

  			row=current_herriak_selected_array[i];

  			if(row.name!=herria.name){
  				result.push(row);
  			}

  		}
  	}

  	this.user_herriak_selected_array=result;

  }

  clickCancel(){

    this.dismissModal('user_herria_back');

  }

  dismissModal(dismiss_type="") {
      
        let data_row:any={user_herriak_selected_array:this.user_herriak_selected_array,dismiss_type:dismiss_type,user_probintzia_herria_aukeratzeko_in:this.user_probintzia_herria_aukeratzeko_in};	

    	  //this.modalController.dismiss(this.herriak_selected_array);

        //this.dismiss_probintzia_modal_by_probintzia_data_row_in();
    	  	
    	  this.modalController.dismiss(data_row);

      //}  	
  }

  clickUserHerriakSelect(){

  	this.dismissModal('user_herria_bidali');

  }
}
